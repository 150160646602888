import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cloak-page3',
  templateUrl: './cloak-page3.component.html',
  styleUrls: ['./cloak-page3.component.css']
})
export class CloakPage3Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/landing-page']);
    }, 300); // redirect after 1 second
  }

}
