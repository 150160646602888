import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cloak-page',
  templateUrl: './cloak-page.component.html',
  styleUrls: ['./cloak-page.component.css']
})
export class CloakPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/2']);
    }, 300); // redirect after 1 second
  }

}
