import * as core from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CloakPageComponent } from './cloak-page/cloak-page.component';
import { CloakPage2Component } from './cloak-page2/cloak-page2.component';
import { CloakPage3Component } from './cloak-page3/cloak-page3.component';

const routes: Routes = [
  { path: '', component: CloakPageComponent },
  { path: '2', component: CloakPage2Component },
  { path: '3', component: CloakPage3Component },
  { path: 'landing-page', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) }
];

@core.NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
